import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";
import { BTabs, BTabItem } from "buefy/dist/esm/tabs";

import { render } from "./common-navigation.html";

@Component({
	render,
	components: {
		BTabs, BTabItem,
	}
})
export default class CommonNavigation extends Vue {
	isLoading: boolean = false;
	activeTabIndex: number = 0;
	activeNavHtml: string = "";
	activeAdditionalHtml: string = "";

	@Prop() initialMenu: string;

	get tabs(): string[] {
		return ["web", "rex", "corporate"]
	};

	mounted() {
		this.activeTabIndex = this.tabs.indexOf(this.initialMenu) ?? 0;
		this.onTabIndexChanged();
	}

	@Watch("activeTabIndex")
	async onTabIndexChanged() {
		let menuName = this.tabs[this.activeTabIndex];
		this.activeNavHtml = await this.getMenu(menuName);
		this.activeAdditionalHtml = await this.getAdditionalContent(menuName);
	}

	async getAdditionalContent(menuName: string): Promise<string> {
		let element = document.getElementById(`menu-${menuName}_additionalcontent`);
		return element?.innerHTML ?? "";
	}

	async getMenu(menuName: string): Promise<string> {
		switch (menuName) {
			case "web":
			case "corporate":
				//return this.loadRemoteMenu(`https://localhost:44300/api/navigation/${menuName}`);
				return this.loadRemoteMenu(`https://kino-thun.ch/api/navigation/${menuName}`);

			default:
				let element = document.getElementById(`menu-${menuName}`);
				return element?.innerHTML ?? "";
		}
	}

	async loadRemoteMenu(url: string) {
		try {
			this.isLoading = true;
			let response = await fetch(url);
			if (response.ok) {
				return await response.text();
			} else {
				return "<em>Menu konnte nicht geladen werden</em>";
			}
		} catch (e) {
			return `<em>Menu konnte nicht geladen werden</em><br /><small><code>${e}</code></small>`;
		}
		finally {
			this.isLoading = false;
		}
	}
}
