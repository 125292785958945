<div>
	<b-tabs class="common-navigation__tabs" type="is-boxed mb-0" v-model="activeTabIndex">
		<b-tab-item>
			<template #header>
				Filme &
				<br /> Vorstellungen
			</template>
		</b-tab-item>
		<b-tab-item>
			<template #header>
				Business &
				<br /> Events
			</template>
		</b-tab-item>
		<b-tab-item>
			<template #header>
				Unternehmen &
				<br /> Kinowerbung
			</template>
		</b-tab-item>
	</b-tabs>

	<div class="expanded-content">
		<div class="scroll-container">
			<div class="columns is-multiline">
				<div class="column">
					<transition>
						<div class="is-flex is-align-items-center is-justify-content-center h-100" v-if="isLoading">
							<spinner type="circular" size="4em" color="white" />
						</div>
						<nav class="navigation--expanded" v-html="activeNavHtml" v-else></nav>
					</transition>
				</div>
			</div>
			<div v-html="activeAdditionalHtml"></div>
		</div>
	</div>
</div>
