import "../Styles/index.scss";

import { defineCustomElement as defineCustomElementCore, type App, type Component } from "vue";
import Flickity from "flickity";
import { Fancybox } from "@fancyapps/ui";
import tippy from "tippy.js";
import EasySpinner from "vue-easy-spinner";
import { initializeMaps } from "@wagich/bluc-map";

import CommonNavigation from "./navigation/common-navigation";
import { SvgIcon } from "./svg-icon";

function defineAndConfigureCustomElement(component: any) {
	return defineCustomElementCore(component.__vccOpts, {
		shadowRoot: false,
		configureApp(app) {
			app.component("svg-icon", SvgIcon);
			app.use(EasySpinner);

			app.config.globalProperties.$colors = {
				red: "#af272f",
				darkred: "#4d0d10",
				lightgray: "#eeeeee",
				darkgray: "#333333",
			};
		},
	})
}

customElements.define("common-navigation", defineAndConfigureCustomElement(CommonNavigation));

initializeMaps();

Fancybox.bind(".fancybox, [data-fancybox], .is-zoomable", {
	groupAttr: "data-gallery"
});

// NAVIGATION
for (let element of document.querySelectorAll(".common-navigation__toggle")) {
	element.addEventListener("click", e => {
		let navigation = document.querySelector<HTMLElement>(".common-navigation");
		navigation?.classList.remove("has-focused-item");
		document.querySelector(".columns--navigation-level-1 .is-focused")?.classList.remove("is-focused");

		navigation?.classList.toggle("is-collapsed");
		navigation?.classList.toggle("is-expanded");
	});
}

let panoramaToggleOpen = document.querySelector<HTMLElement>(".toggle-panorama--open");
let panoramaToggleClose = document.querySelector<HTMLElement>(".toggle-panorama--close");

function openViewset(e: Event) {
	let viewset = (e.target as HTMLElement).parentElement;
	viewset?.classList.add("is-viewset-open");

	// add delay to prevent immediate closing when mouse moves outside while switching elements
	window.setTimeout(() => {
		viewset?.addEventListener("mouseleave", closeViewset, { once: true });
	}, 400);

	document.body.addEventListener("touchstart", e => {
		if (!viewset?.contains(e.target as HTMLElement)) {
			closeViewset();
		}
	});

	function closeViewset() {
		viewset?.classList.remove("is-viewset-open");
	}
}

for (let element of document.querySelectorAll(".virtualtour-viewset-title")) {
	element.addEventListener("mouseenter", openViewset);
	element.addEventListener("touchstart", openViewset);
}

let tourFlickity = new Flickity(".virtualtour-sequence", {
	setGallerySize: false
});

for (let element of document.querySelectorAll(".virtualtour-view")) {
	element.addEventListener("click", e => {
		var viewId = (e.target as HTMLElement).dataset["viewid"];
		var largeViewIndex = [...document.querySelectorAll(".virtualtour-largeview")].findIndex(e => e.id === "largeview-" + viewId);
		tourFlickity.select(largeViewIndex);

		document.body.classList.add("is-panorama-open");
		tourFlickity.resize();
		panoramaToggleOpen?.classList.remove("is-active");
		panoramaToggleClose?.classList.add("is-active");
	});
}

if (document.body.classList.contains("is-panorama-open")) {
	panoramaToggleOpen?.classList.remove("is-active");
	panoramaToggleClose?.classList.add("is-active");
}

for (let element of document.querySelectorAll(".toggle-panorama")) {
	element.addEventListener("click", () => {
		document.body.classList.toggle("is-panorama-open");
		panoramaToggleOpen?.classList.toggle("is-active");
		panoramaToggleClose?.classList.toggle("is-active");
	});
}

for (let element of document.querySelectorAll<HTMLElement>(".virtualtour-hotspot-icon")) {
	let content = element.parentElement?.querySelector(".virtualtour-hotspot-content")?.innerHTML;
	tippy(element, {
		content: content,
		allowHTML: true,
		interactive: true,
		theme: "dark",
		animation: "scale-subtle",
		maxWidth: 450
	});
}
